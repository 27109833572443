<script setup lang="ts">
const props = defineProps({
  size: {
    type: String as PropType<'normal'|'big'|'mobile'>,
    default: 'normal'
  },
  roundedEdges: Boolean,
  userInfo: Object
});
const vipTiersName = computed(() => props.userInfo?.vipTiersName || '0');
const imageSrc = computed(() => {
  if (vipTiersName.value === '3') {
    return '/images/shine-3.png';
  }
  if (vipTiersName.value === '2') {
    return '/images/shine-2.png';
  }
  if (vipTiersName.value === '1') {
    return '/images/shine-1.png';
  }
  return '/images/shine.png';
});
</script>
<template>
  <div :class="['user-tiers', `user-tiers-${size}`, `user-tiers-${vipTiersName}`, roundedEdges && 'user-tiers-rounded-edges']">
    <img class="user-tiers-img" :src="imageSrc" />
    <div class="user-tiers-content">
      <slot></slot>
    </div>
  </div>
</template>
<style>
.user-tiers {
  position: relative;
  color: rgba(0,0,0,0.87);
}
.user-tiers .user-tiers-img {
  position: absolute;
  object-fit: contain;
}
.user-tiers.user-tiers-normal {
  width: 60px;
  height: 60px;
}
.user-tiers-big {
  width: 150px;
  height: 90px;
}
.user-tiers-big .user-tiers-img {
  width: 150px;
  height: 90px;
}
.user-tiers-content {
  position: absolute;
  z-index: 0;
  width: 98%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.user-tiers.user-tiers-normal.user-tiers-rounded-edges .user-tiers-img {
  left: 5px;
  top: 14px;
  width: 49px;
  height: 31px;
}
.user-tiers.user-tiers-mobile {
  width: 0.65rem;
  height: 0.65rem;
}
.user-tiers.user-tiers-mobile.user-tiers-rounded-edges .user-tiers-img {
  left: 0;
  top: 0;
  width: 0.65rem;
  height: 0.65rem;
}
.user-tiers.user-tiers-rounded-edges {
  border: 1px solid #78D9E1;
  border-radius: 999px;
}
.user-tiers-1 {
  color: #fff;
}
.user-tiers-1.user-tiers-rounded-edges {
  border-color: #DC226B;
}
.user-tiers-2.user-tiers-rounded-edges {
  border-color: #FF7D25;
}
.user-tiers-3.user-tiers-rounded-edges {
  border-color: #C8C8C8;
}
</style>